import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import axios from "axios";
import FlightTracker from "./FlightTracker";
import ServiceForm from "./ServiceForm";
import AddFlightForm from "./AddFlightForm";
import ScheduledServices from "./ScheduledServices";
import WeeklyFlightSchedule from "../WeeklyFlightSchedule";
import LinearProgressWithLabel from "../ui/LinearProgressWithLabel";
import TabPanel from "../ui/TabPanel";
import {
  formatDate,
  findIndexInbound,
  findIndexOutbound,
  getDate,
} from "../../helpers";
import { Tab, Tabs, Box, Button, ButtonGroup } from "@mui/material";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { blue, green } from "@mui/material/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import { useTranslation } from "react-i18next";

alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 3);

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#00e676",
    },
    success: {
      main: green[600],
    },
  },
});

const Schedule = ({ flights, airports, limits, can_create, admin }) => {
  let storageFlights = localStorage.getItem("flights");
  storageFlights = storageFlights != null ? JSON.parse(storageFlights) : {};
  let storageDate =
    localStorage.getItem("search_date") ||
    new Date().toISOString().split("T")[0];
  let flightIds = Object.keys(flights);

  const { t, i18n } = useTranslation();
  const airport = airports[0];
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState({});
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [showAddFlightForm, setShowAddFlightForm] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [flight, setFlight] = useState([]);
  const [service, setService] = useState({});
  const [services, setServices] = useState([]);
  const [searchDate, setSearchDate] = useState(storageDate);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [increment, setIncrement] = useState(0);
  const [runTableUpdate, setRunTableUpdate] = useState(0);

  useEffect(() => {
    getDataForWeeklySchedule(searchDate, airport.id);
    i18n.changeLanguage(admin.language);
    const onlineHandler = () => {
      setIsOnline(true);
    };

    const offlineHandler = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    getServicesByDate(localStorage.getItem("search_date"));
    if (storageFlights && Object.keys(storageFlights).length > 0) {
      setData(storageFlights);
    } else {
      refreshTable(searchDate);
    }

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    if (runTableUpdate === 1) {
      getDataForWeeklySchedule(searchDate, airport.id);
    }
  }, [runTableUpdate, setRunTableUpdate]);

  useEffect(() => {
    setIncrement(Math.floor(flightIds.length / 10));
  }, [flightIds]);

  useEffect(() => {
    const interval = setInterval(() => {
      getServicesByDate(localStorage.getItem("search_date"));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    localStorage.setItem("flights", JSON.stringify(data));
  }, [data, setData]);

  useEffect(() => {
    localStorage.setItem("search_date", searchDate);
  }, [searchDate, setSearchDate]);

  const getDataForWeeklySchedule = (date, airportId) => {
    axios
      .get(
        `/admin/registries/weekly_schedule?date=${date}&airport_id=${airportId}`
      )
      .then((response) => {
        setRows(response.data);

        let errors = [];

        response.data.forEach((flight) => {
          if (flight.cancelled === true && flight.cancel_form === false) {
            errors.push(
              `Flight ${flight.flight_number1 || flight.flight_number2} (ID: ${
                flight.id
              }) to/from ${flight.city1 || flight.city2} scheduled at ${
                flight.scheduled_time1 || flight.scheduled_time2
              } requires completion of the cancellation services form.`
            );
          }
        });

        if (errors.length > 0) {
          alertify
            .alert()
            .setting({
              title:
                '<span style="color: red;font-weight: bold;">Action Required: Complete Cancellation Forms</span>',
              message: `The following flights were cancelled but are missing a completed cancellation services form. Please take action to resolve this:<br><br>${errors.join(
                "<br>"
              )}`,
            })
            .show();
        }
      });
  };

  const getServicesByDate = (date) => {
    fetch(`/admin/services/get_all_by_date?date=${date}&format=json`)
      .then((response) => response.json())
      .then((json) => {
        setServices(json.services);
      });
  };
  const getFlightDetails = async (flight, type, date) => {
    let newFlightData = flight;
    const { number } = flight[type];
    const flightType = type === "inbound" ? "destination" : "origin";
    const chars = number.slice(0, number.search(/\d/));
    const numbs = number.replace(chars, "");
    const [year, month, day] = date.split("-");
    const oneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    const searchDay = Date.parse(date);

    let url =
      searchDay <= oneDay
        ? `/admin/flights/details?flight=${number}&date=${year}-${month}-${day}&format=json`
        : `/admin/flights/schedules?date=${date}&type=${flightType}&airport=${airport.iata}&airline=${chars}&flight_number=${numbs}`;

    const response = await fetch(url);
    const json = await response.json();
    newFlightData[type].data = json;

    if (json?.flights && json?.flights?.length > 0) {
      const index =
        type === "inbound"
          ? json?.flights?.length > 1
            ? findIndexInbound(json?.flights)
            : 0
          : json?.flights?.length > 1
          ? findIndexOutbound(json?.flights)
          : 0;
      const flightDate =
        type === "inbound"
          ? json?.flights[index]?.scheduled_in
            ? getDate(json?.flights[index].scheduled_in)
            : undefined
          : json?.flights[index]?.scheduled_out
          ? getDate(json?.flights[index].scheduled_out)
          : undefined;
      if (flightDate && flightDate < date) {
        let tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() + 2);
        url = `/admin/flights/details?flight=${number}&date=${getDate(
          tomorrow
        )}&format=json`;
        const response2 = await fetch(url);
        const json2 = await response2.json();
        newFlightData[type].data = json2;
      }
    }
    return newFlightData;
  };

  // Get latest flights details.
  const refreshTable = (date) => {
    const newData = {};
    axios
      .get(`/admin/flights/get_updated_list?date=${date}`)
      .then((response) => {
        if (response.status === 200) {
          const flightsList = response.data.flights;

          localStorage.setItem("flights", JSON.stringify(flightsList));
          flightIds = Object.keys(flightsList);

          if (flightIds.length > 0) {
            for (let id in flightsList) {
              if (![null, "", "-"].includes(flightsList[id].inbound.number)) {
                getFlightDetails(flightsList[id], "inbound", date).then(
                  (flightDetails) => {
                    newData[id] = flightDetails;
                  }
                );
              }
              if (![null, "", "-"].includes(flightsList[id].outbound.number)) {
                getFlightDetails(flightsList[id], "outbound", date).then(
                  (flightDetails) => {
                    newData[id] = flightDetails;
                  }
                );
              }
            }

            const timer = setInterval(() => {
              setProgress((prevProgress) => prevProgress + increment);
            }, increment * 50);

            setTimeout(() => {
              setData(newData);
              clearInterval(timer);
              setActive(false);
              setProgress(0);
            }, (100 / increment) * increment * 50);
          }
        }
      });
  };

  const onClickHandler = (params, e) => {
    if (params.from === "-" && params.dest === "-") {
      alertify.error(
        "Sorry, your request could not be processed as it contains invalid data."
      );
    } else {
      setShowServiceForm(!showServiceForm);
      setFlight(params);
    }
  };

  const onFlightTypeSelectHandler = (flight, type) => {
    const isArrival = type === "arrival";
    const date = flight.date_a != "-" ? formatDate(flight.date_a) : flight.date;
    const regId = isArrival ? flight.line_id1 : flight.line_id2;
    const url = isArrival
      ? `/admin/services.json?q[flight_date_gteq]=${date}&q[flight_date_lteq]=${date}&q[flight_in_contains]=${flight.in}&q[registry_line_id_equals]=${regId}`
      : `/admin/services.json?q[flight_date2_gteq]=${date}&q[flight_date2_lteq]=${date}&q[flight_out_contains]=${flight.out}&q[registry_line_id_equals]=${regId}`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        const newData = json.length > 0 ? json[0] : {};
        setService(newData);
      });
  };

  const onDateChangeHandler = (e) => {
    localStorage.removeItem("flights");
    let { value } = e.target;
    setSearchDate(value);
    setActive(true);
    refreshTable(value);
    getDataForWeeklySchedule(value, airport.id);
  };

  const refreshButtonClickHandler = () => {
    localStorage.removeItem("flights");
    setActive(true);
    refreshTable(searchDate);
  };

  const addFlightButtonClickHandler = () => {
    setShowAddFlightForm(true);
  };

  const changeTabHandler = (event, newValue) => {
    if (newValue === 0) {
      getDataForWeeklySchedule(searchDate, airport.id);
    }
    setValue(newValue);
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <h2 className="dh2 mb-0">{t("schedule_board")}</h2>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <div style={{ margin: "20px auto 10px" }}>
          <ButtonGroup>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={refreshButtonClickHandler}
              disabled={active}
            >
              {t("refresh")}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={addFlightButtonClickHandler}
              startIcon={<AddCircleOutlineIcon />}
              disabled={active}
            >
              {t("flight")}
            </Button>
          </ButtonGroup>
          <input
            type="date"
            disabled={active}
            name="search_date"
            value={searchDate}
            min={limits.min}
            max={limits.max}
            onChange={onDateChangeHandler}
            style={{ width: "150px", float: "right" }}
          />
        </div>
        <Tabs
          value={value}
          onChange={changeTabHandler}
          aria-label="schedule tabs"
          centered
        >
          <Tab
            icon={<CalendarMonthIcon />}
            label={t("tab1")}
            iconPosition="start"
          />
          <Tab
            icon={<ConnectingAirportsIcon />}
            label={t("tab2")}
            iconPosition="start"
          />
        </Tabs>
        <TabPanel
          value={value}
          index={0}
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          {active ? (
            <LinearProgressWithLabel value={progress} />
          ) : (
            <WeeklyFlightSchedule
              rows={rows}
              locale={t}
              date={searchDate}
              setRows={setRows}
            />
          )}
          {showAddFlightForm && (
            <MuiThemeProvider theme={theme}>
              <AddFlightForm
                onClick={setShowAddFlightForm}
                showForm={showAddFlightForm}
                date={storageDate}
                flights={rows}
                airport={airport}
                setRunTableUpdate={setRunTableUpdate}
                locale={t}
              />
            </MuiThemeProvider>
          )}
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          {active ? (
            <LinearProgressWithLabel value={progress} />
          ) : (
            <FlightTracker
              rows={data}
              onClick={can_create ? onClickHandler : null}
              services={services}
              locale={t}
            />
          )}
          {showServiceForm && (
            <MuiThemeProvider theme={theme}>
              <ServiceForm
                locale={t}
                onClick={setShowServiceForm}
                showForm={showServiceForm}
                flight={flight}
                service={service}
                setService={setService}
                onTypeSelect={onFlightTypeSelectHandler}
              />
            </MuiThemeProvider>
          )}
        </TabPanel>
      </Box>
      {services.length > 0 && (
        <ScheduledServices
          searchDate={searchDate}
          services={services}
          locale={t}
        />
      )}
    </div>
  );
};

export default Schedule;
